import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between" }
const _hoisted_2 = { class: "p-input-icon-left p-col-4" }
const _hoisted_3 = { class: "table-filters-panel" }
const _hoisted_4 = { class: "icon__container p-mr-2" }
const _hoisted_5 = ["aria-label"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "foodToDeactivateName" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "foodToDeactivateName" }

import { Food, FoodCategory, FoodDto, MealType } from '@/models/NutritionalPlan';
import { nutritionalPlanService } from '@/services/NutritionalPlanService';
import { ServiceError } from '@/services/util/ServiceError';
import { FilterMatchMode } from 'primevue/api';
import { useToast } from 'primevue/usetoast';
import { computed, PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'FoodTable',
  props: {
  foods: {
    type: Array as PropType<Food[]>,
  },
},
  emits: ['see-food-click', 'edit-food-click', 'delete-food-click', 'deactivate-food-click'],
  setup(__props, { emit: __emit }) {

const { t } = useI18n();
const toast = useToast();
const filterBy = ref('all');
const showDeactivateDialog = ref(false);
const foodToEdit = ref<FoodDto>();
const updating = ref(false);

const emits = __emit;

const props = __props;

const loading = computed(() => !props.foods);
const showPaginator = computed(() => props.foods && props.foods.length > 10);
const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  category: { value: [], matchMode: FilterMatchMode.IN },
  mealTypes: { value: [], matchMode: FilterMatchMode.CONTAINS },
  healzValue: { value: [], matchMode: FilterMatchMode.IN },
});

const initFilters = () => {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    category: { value: [], matchMode: FilterMatchMode.IN },
    mealTypes: { value: [], matchMode: FilterMatchMode.CONTAINS },
    healzValue: { value: [], matchMode: FilterMatchMode.IN },
  };

  filterBy.value = 'all';
};
initFilters();

const categoriesOptions = computed(() => {
  return Object.keys(FoodCategory)
    .map((key) => ({
      label: t(`food.categories.${key}`),
      value: key,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
});

const mealTypesOptions = computed(() => {
  return Object.keys(MealType)
    .map((key) => ({
      label: t(`food.mealTypes.${key}`),
      value: key,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
});

const originOptions = ref([
  {
    label: t(`food.origins.healz`),
    value: true,
  },
  {
    label: t(`food.origins.personalized`),
    value: false,
  },
]);

const foodFiltered = computed(() => {
  if (!props.foods) {
    return [];
  }

  switch (filterBy.value) {
    case 'all':
      return props.foods;
    case 'active':
      return props.foods.filter((f) => !f.deactivated);
    case 'inactive':
      return props.foods.filter((f) => f.deactivated);

    default:
      return props.foods;
  }
});

const renderMealTypes = (types: string[]) => {
  if (!types) {
    return '';
  }
  const translatedTypes = types
    .filter((type) => Object.keys(MealType).includes(type))
    .map((type) => t(`food.mealTypes.${type}`));
  return translatedTypes.join(', ');
};

const onViewDetails = (food: Food) => {
  emits('see-food-click', food);
};

const onCancelDeactivated = () => {
  showDeactivateDialog.value = false;
  if (foodToEdit.value) {
    foodToEdit.value.deactivated = !foodToEdit.value?.deactivated;
    foodToEdit.value = undefined;
  }
};

const onDeactivatedClick = (food: FoodDto) => {
  foodToEdit.value = food;
  showDeactivateDialog.value = true;
};

const onDeactivated = async () => {
  updating.value = true;
  if (foodToEdit.value) {
    const result = await nutritionalPlanService.updateDeactivated(foodToEdit.value.id, foodToEdit.value.deactivated);

    if (result instanceof ServiceError) {
      toast.add({
        severity: 'error',
        summary: `${t('messages.notifications.errorUpdateFood')} ${t('messages.notifications.tryLater')}`,
        life: 3000,
      });
    } else {
      toast.add({ severity: 'success', summary: `${t('messages.notifications.successUpdateFood')}`, life: 3000 });
      showDeactivateDialog.value = false;
    }
  }
  updating.value = false;
};

const changeFilter = (newFilter: string) => {
  filterBy.value = newFilter;
};

const rowClickEvent = (event: any) => {
  onViewDetails(event.data);
};

return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Column = _resolveComponent("Column")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (foodFiltered.value)
      ? (_openBlock(), _createBlock(_component_DataTable, {
          key: 0,
          ref: "foodsTable",
          filters: filters.value,
          "onUpdate:filters": _cache[6] || (_cache[6] = ($event: any) => ((filters).value = $event)),
          "filter-display": "menu",
          value: foodFiltered.value,
          "data-key": "id",
          rows: 10,
          "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
          paginator: showPaginator.value,
          "responsive-layout": "scroll",
          loading: loading.value,
          "global-filter-fields": ['name', 'email', 'phoneNumber'],
          "sort-field": "name",
          "sort-order": 1,
          "removable-sort": "",
          rowHover: true,
          onRowClick: rowClickEvent
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("span", _hoisted_2, [
                _cache[8] || (_cache[8] = _createElementVNode("i", {
                  class: "icon-search",
                  "aria-hidden": "true"
                }, null, -1)),
                _createVNode(_component_InputText, {
                  modelValue: filters.value['global'].value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filters.value['global'].value) = $event)),
                  placeholder: _ctx.$t('table.search.default')
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _createVNode(_component_Button, {
                type: "button",
                class: "p-button p-component p-button-secondary p-button-medium p-button-icon",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (initFilters()))
              }, {
                default: _withCtx(() => [
                  _cache[9] || (_cache[9] = _createElementVNode("i", {
                    class: "icon-trash_empty",
                    "aria-hidden": "true"
                  }, null, -1)),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('table.clearFilters')), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", {
                class: _normalizeClass(["filter-button", { active: filterBy.value === 'all' }]),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (changeFilter('all')))
              }, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('food.all')), 1)
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(["filter-button", { active: filterBy.value === 'active' }]),
                onClick: _cache[3] || (_cache[3] = ($event: any) => (changeFilter('active')))
              }, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('food.active')), 1)
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(["filter-button", { active: filterBy.value === 'inactive' }]),
                onClick: _cache[4] || (_cache[4] = ($event: any) => (changeFilter('inactive')))
              }, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('food.inactive')), 1)
              ], 2)
            ])
          ]),
          empty: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('table.not-found.foods')), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "healzValue",
              "filter-field": "healzValue",
              header: _ctx.$t('food.origin'),
              showClearButton: false,
              showApplyButton: false,
              showFilterMatchModes: false
            }, {
              filter: _withCtx(({ filterModel, filterCallback }) => [
                _createVNode(_component_MultiSelect, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  options: originOptions.value,
                  placeholder: _ctx.$t('food.filterMealType'),
                  "option-label": "label",
                  "option-value": "value",
                  class: "p-column-filter",
                  onChange: ($event: any) => (filterCallback())
                }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "placeholder", "onChange"])
              ]),
              body: _withCtx((slotProps) => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("i", {
                    class: _normalizeClass({ 'icon-healz': slotProps.data.healzValue, 'icon-custom': !slotProps.data.healzValue }),
                    "aria-hidden": "true"
                  }, null, 2)
                ])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "name",
              header: _ctx.$t('food.name'),
              sortable: ""
            }, {
              body: _withCtx((slotProps) => [
                _createTextVNode(_toDisplayString(slotProps.data.name), 1)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "category",
              "filter-field": "category",
              header: _ctx.$t('food.category'),
              showClearButton: false,
              showApplyButton: false,
              showFilterMatchModes: false
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(_ctx.$t(`food.categories.${data.category}`)), 1)
              ]),
              filter: _withCtx(({ filterModel, filterCallback }) => [
                _createVNode(_component_MultiSelect, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  options: categoriesOptions.value,
                  placeholder: _ctx.$t('food.filterCategory'),
                  "option-label": "label",
                  "option-value": "value",
                  class: "p-column-filter",
                  onChange: ($event: any) => (filterCallback())
                }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "placeholder", "onChange"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "mealTypes",
              "filter-field": "mealTypes",
              header: _ctx.$t('food.mealType'),
              showClearButton: false,
              showApplyButton: false,
              showFilterMatchModes: false
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(renderMealTypes(data.mealTypes)), 1)
              ]),
              filter: _withCtx(({ filterModel, filterCallback }) => [
                _createVNode(_component_MultiSelect, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  options: mealTypesOptions.value,
                  placeholder: _ctx.$t('food.filterMealType'),
                  "option-label": "label",
                  "option-value": "value",
                  class: "p-column-filter",
                  onChange: ($event: any) => (filterCallback())
                }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "placeholder", "onChange"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "calories",
              header: _ctx.$t('food.calories')
            }, null, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "protein",
              header: _ctx.$t('food.protein')
            }, null, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "fat",
              header: _ctx.$t('food.fat')
            }, null, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "carbohydrates",
              header: _ctx.$t('food.carbohydrates')
            }, null, 8, ["header"]),
            _createVNode(_component_Column, {
              header: _ctx.$t('table.actions')
            }, {
              body: _withCtx((slotProps) => [
                _withDirectives((_openBlock(), _createBlock(_component_Button, {
                  class: "p-button-only-icon",
                  onClick: ($event: any) => (onViewDetails(slotProps.data))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("i", {
                      class: "icon-show",
                      "aria-label": _ctx.$t('common.edit'),
                      "aria-hidden": "true"
                    }, null, 8, _hoisted_5)
                  ]),
                  _: 2
                }, 1032, ["onClick"])), [
                  [
                    _directive_tooltip,
                    _ctx.$t('common.edit'),
                    void 0,
                    { bottom: true }
                  ]
                ]),
                _createVNode(_component_InputSwitch, {
                  modelValue: slotProps.data.deactivated,
                  "onUpdate:modelValue": ($event: any) => ((slotProps.data.deactivated) = $event),
                  inputId: "switchEnabled",
                  "true-value": false,
                  "false-value": true,
                  onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"])),
                  onChange: ($event: any) => (onDeactivatedClick(slotProps.data))
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]),
              _: 1
            }, 8, ["header"])
          ]),
          _: 1
        }, 8, ["filters", "value", "paginator", "loading"]))
      : _createCommentVNode("", true),
    _createVNode(_component_Dialog, {
      visible: showDeactivateDialog.value,
      "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((showDeactivateDialog).value = $event)),
      header: 
      foodToEdit.value?.deactivated
        ? _ctx.$t('food.deactivateFoodModal.deactivateTitle')
        : _ctx.$t('food.deactivateFoodModal.activateTitle')
    ,
      modal: true
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.$t('common.cancel'),
          class: "p-button p-component p-button-secondary p-button-medium",
          onClick: onCancelDeactivated
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          label: foodToEdit.value?.deactivated ? _ctx.$t('common.deactivate') : _ctx.$t('common.activate'),
          class: _normalizeClass(["p-button p-component p-button-medium", {
          'p-button-primary': !foodToEdit.value?.deactivated,
          'p-button-secondary': foodToEdit.value?.deactivated,
          'delete-food-confirm-button': foodToEdit.value?.deactivated,
        }]),
          loading: updating.value,
          onClick: onDeactivated
        }, null, 8, ["label", "class", "loading"])
      ]),
      default: _withCtx(() => [
        (foodToEdit.value?.deactivated)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
              _createTextVNode(_toDisplayString(_ctx.$t('food.deactivateFoodModal.deactivateDescription1')) + " ", 1),
              _createElementVNode("span", _hoisted_7, _toDisplayString(foodToEdit.value.name), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('food.deactivateFoodModal.deactivateDescription2')), 1)
            ]))
          : _createCommentVNode("", true),
        (!foodToEdit.value?.deactivated)
          ? (_openBlock(), _createElementBlock("p", _hoisted_8, [
              _createTextVNode(_toDisplayString(_ctx.$t('food.deactivateFoodModal.activateDescription1')) + " ", 1),
              _createElementVNode("span", _hoisted_9, _toDisplayString(foodToEdit.value?.name), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('food.deactivateFoodModal.activateDescription2')), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible", "header"])
  ], 64))
}
}

})